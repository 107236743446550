import React from "react";
import clsx from "clsx";

import * as styles from "./button.module.scss";

const Button = ({ className, children, ...props }) => (
  <button className={clsx(styles.button, className)} type="button" {...props}>
    {children}
  </button>
);

export default Button;
