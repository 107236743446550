import React from "react";
import clsx from "clsx";

import * as styles from "./glossy-card.module.scss";

const GlossyCard = ({ className, children, ...props }) => (
  <div className={clsx(styles.card, className)} {...props}>
    {children}
  </div>
);

export default GlossyCard;
