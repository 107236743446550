import React from "react";
import clsx from "clsx";

import Header from "../../common/Header/Header";

import * as styles from "./page.module.scss";

const Page = ({ children, className, ...props }) => (
  <>
    <Header />
    <div className={clsx(styles.page, className)} {...props}>
      {children}
    </div>
  </>
);

export default Page;
