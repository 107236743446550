import React from "react";
import { useRecoilValue } from "recoil";

import Section from "../../ui/Section/Section";
import Case from "../../ui/Case/Case";

import { casesSectionState } from "../../../data/state";
import * as styles from "./cases-section.module.scss";

const CasesSection = () => {
  const { title, cases, slug } = useRecoilValue(casesSectionState);
  if (!title || !cases) return null;

  return (
    <Section title={title} className={styles.section} id={slug}>
      {cases.map((item, i) => (
        <Case {...item} key={i} reversed={i % 2 !== 0} />
      ))}
    </Section>
  );
};

export default CasesSection;
