import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useRecoilState } from "recoil";
import { Helmet } from "react-helmet";

import dataState from "../data/state";

import HomeSection from "../components/common/HomeSection/HomeSection";
import CasesSection from "../components/common/CasesSection/CasesSection";
import Footer from "../components/ui/Footer/Footer";
import ContactSection from "../components/common/ContactSection/ContactSection";
import Page from "../components/ui/Page/Page";
import ServicesSection from "../components/common/ServicesSection/ServicesSection";
import TeamSection from "../components/common/TeamSection/TeamSection";
import { useTranslation } from "gatsby-plugin-react-i18next";

// markup
const IndexPage = ({ data }) => {
  const [, setData] = useRecoilState(dataState);
  const { t } = useTranslation();

  useEffect(() => {
    setData(data);
  }, [data, setData]);

  // console.log(data);

  return (
    <Page>
      <Helmet>
        <title>{t("Команда PPnP")}</title>
        <meta name="description" content={data.datoCmsSetting.homeText} />
        <meta name="yandex-verification" content="ca9a11062897f86a" />
      </Helmet>

      <HomeSection />
      <CasesSection />
      <ServicesSection />
      <TeamSection />
      <ContactSection />
      <Footer />
    </Page>
  );
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsSetting(locale: { eq: $language }) {
      homeText
    }
    datoCmsMenu(locale: { eq: $language }) {
      items {
        text
        link
      }
    }
    datoCmsCasesSection(locale: { eq: $language }) {
      title
      slug
      linkText
      cases {
        photo {
          gatsbyImageData(
            width: 550
            placeholder: BLURRED
            forceBlurhash: false
          )
        }
        title
        description
      }
    }
    datoCmsTeamSection(locale: { eq: $language }) {
      title
      subtitle
      slug
      description
      photos {
        gatsbyImageData(width: 550, placeholder: BLURRED, forceBlurhash: false)
      }
      link {
        href
        text
      }
    }
    datoCmsServicesSection(locale: { eq: $language }) {
      title
      description
      slug
      skills {
        categories
        title
      }
    }
    datoCmsContactForm(locale: { eq: $language }) {
      title
      slug
      description
      namePlaceholder
      emailPlaceholder
      phonePlaceholder
      msgPlaceholder
      agreement
      submitText
    }
    datoCmsFooter(locale: { eq: $language }) {
      links {
        text
        href
      }
      email
      phone
    }
  }
`;
