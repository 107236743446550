import React, { useMemo } from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { titleCase } from "title-case";
import clsx from "clsx";

import * as styles from "./language-switcher.module.scss";

const LanguageSwitcher = ({ className }) => {
  const { languages, originalPath, language } = useI18next();

  const availableLanguages = useMemo(() => {
    return languages.filter((lng) => lng !== language);
  }, [languages, language]);

  return (
    <div className={clsx(styles.switcher, className)}>
      {availableLanguages.map((lng) => (
        <Link
          to={originalPath}
          language={lng}
          className={styles.language}
          key={lng}
        >
          {titleCase(lng)}
        </Link>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
