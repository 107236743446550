import React, { forwardRef } from "react";
import clsx from "clsx";

import * as styles from "./checkbox.module.scss";

const Checkbox = forwardRef(({ className, children, ...props }, ref) => (
  <label className={clsx(styles.checkbox, className)}>
    <input type="checkbox" className={styles.input} {...props} ref={ref} />
    <span className={styles.checkmark} />
    {children}
  </label>
));

export default Checkbox;
