import React from "react";
import { useRecoilValue } from "recoil";
import ReactMarkdown from "react-markdown";

import Button from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import Textarea from "../Textarea/Textarea";

import { contactFormState } from "../../../data/state";
import * as styles from "./contact-form.module.scss";

const ContactForm = ({ register, onSubmit }) => {
  const { agreement, submitText, ...tips } = useRecoilValue(contactFormState);

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <Input placeholder={tips.namePlaceholder} {...register("name")} />

      <div className={styles.columns}>
        <Input placeholder={tips.emailPlaceholder} {...register("email")} />
        <Input placeholder={tips.phonePlaceholder} {...register("phone")} />
      </div>

      <Textarea placeholder={tips.msgPlaceholder} {...register("message")} />

      <Checkbox {...register("agreement")}>
        <ReactMarkdown>{agreement}</ReactMarkdown>
      </Checkbox>

      <Button type="submit">{submitText}</Button>
    </form>
  );
};

export default ContactForm;
