import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";

import ArrowDown from "../../ui/ArrowDown/ArrowDown";
import Section from "../../ui/Section/Section";
import ServiceCategory from "../../ui/ServiceCategory/ServiceCategory";

import * as styles from "./services-section.module.scss";
import { servicesSectionState } from "../../../data/state";

const ServicesSection = () => {
  const { title, description, skills, slug } = useRecoilValue(
    servicesSectionState
  );
  if (!title || !description || !skills) return null;

  return (
    <Section className={styles.section} id={slug}>
      <div className={styles.title}>
        <h2>{title}</h2>
        <p>{description}</p>
        <ArrowDown animated />
      </div>

      <div className={styles.grid}>
        {skills.map(({ title, categories }, index) => (
          <Fragment key={index}>
            <ServiceCategory title={title} className={styles.card}>
              {categories.split("\n").map((category) => (
                <p key={category}>{category}</p>
              ))}
            </ServiceCategory>

            {index % 2 === 0 && (
              <>
                <div className={styles.gap} />
                <div className={styles.gap} />
              </>
            )}
          </Fragment>
        ))}
      </div>
    </Section>
  );
};

export default ServicesSection;
