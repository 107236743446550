import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";

import FooterLink from "../FooterLink/FooterLink";

import { footerState } from "../../../data/state";
import * as styles from "./footer.module.scss";

const Footer = () => {
  const { email, phone, links } = useRecoilValue(footerState);

  const footerLinks = useMemo(() => {
    const result = Array.from(links || []);

    // Copyright
    result.push({ text: new Date().getFullYear(), href: "#" });

    // Contact info
    result.push({ text: email, href: `mailto:${email}` });
    result.push({ text: phone, href: `tel:${phone}` });

    return result;
  }, [links, email, phone]);

  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        {footerLinks.map(({ text, href }, i) => (
          <FooterLink href={href} key={i} className={styles.link}>
            {text}
          </FooterLink>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
