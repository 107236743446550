import React from "react";
import { useRecoilValue } from "recoil";

import HeroSection from "../../ui/HeroSection/HeroSection";

import { settingsState } from "../../../data/state";
import ReactMarkdown from "react-markdown";

const HomeSection = () => {
  const { homeText } = useRecoilValue(settingsState);

  return (
    <HeroSection>
      <h1 style={{ whiteSpace: "pre-wrap" }}>
        <ReactMarkdown>{homeText}</ReactMarkdown>
      </h1>
    </HeroSection>
  );
};

export default HomeSection;
