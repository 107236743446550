import React from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";

import ContactForm from "../../ui/ContactForm/ContactForm";
import Section from "../../ui/Section/Section";

import { contactFormState } from "../../../data/state";
import * as styles from "./contact-section.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "gatsby-plugin-react-i18next";

const MySwal = withReactContent(Swal);

const ContactSection = () => {
  const { title, description, slug } = useRecoilValue(contactFormState);
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    const form = new FormData();

    for (const key in data) {
      form.append(key, data[key]);
    }

    fetch("https://ppnp.me/contact/handler.php", {
      method: "POST",
      body: form,
    })
      .then((response) => response.text())
      .then((html) => {
        MySwal.fire({
          html: t(html),
        });
      })
      .catch(console.error);
  };

  return (
    <Section title={title} id={slug}>
      <div className={styles.contacts}>
        <h4 className={styles.text}>{description}</h4>
        <ContactForm register={register} onSubmit={handleSubmit(onSubmit)} />
      </div>
    </Section>
  );
};

export default ContactSection;
