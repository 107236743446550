import React from "react";
import clsx from "clsx";

import Arrow from "../../../images/arrow-down.inline.svg";

import * as styles from "./arrow-down.module.scss";

const ArrowDown = ({ animated, className }) => (
  <Arrow
    className={clsx(styles.arrow, animated && styles.animated, className)}
  />
);

export default ArrowDown;
