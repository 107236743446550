import React, { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { GatsbyImage } from "gatsby-plugin-image";

import ArrowLink from "../../ui/ArrowLink/ArrowLink";

import * as styles from "./team-section.module.scss";
import { teamSectionState } from "../../../data/state";

const SCROLL_INTERVAL = 50; // ms
const SCROLL_STEP = 1;

const TeamSection = () => {
  const { photos, link, slug, ...info } = useRecoilValue(teamSectionState);

  const photosRef = useRef(null);
  const scrollValue = useRef(null);

  const updateScroll = () => {
    if (!photosRef.current) return;
    const { scrollTop, offsetHeight, scrollHeight } = photosRef.current;

    if (scrollTop === 0) scrollValue.current = SCROLL_STEP;
    if (scrollTop + offsetHeight === scrollHeight) scrollValue.current *= -1;

    photosRef.current.scrollBy({
      top: scrollValue.current,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setInterval(updateScroll, SCROLL_INTERVAL);
    return () => clearInterval(updateScroll);
  }, []);

  if (!photos) return null;

  return (
    <section className={styles.section} id={slug}>
      <h2 className={styles.title}>{info.title}</h2>
      <div className={styles.content}>
        <div className={styles.photos} ref={photosRef}>
          {photos.map((photo, index) => (
            <GatsbyImage
              image={photo.gatsbyImageData}
              className={styles.photo}
              key={index}
              alt=""
            />
          ))}
        </div>
        <div className={styles.info}>
          <strong>{info.subtitle}</strong>
          <p>{info.description}</p>
          {/* <ArrowLink href={link.href} className={styles.link}>
            {link.text}
          </ArrowLink> */}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
