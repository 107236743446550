import React, { forwardRef } from "react";
import clsx from "clsx";

import * as styles from "./input.module.scss";

const Input = forwardRef(({ className, ...props }, ref) => (
  <input className={clsx(styles.input, className)} {...props} ref={ref} />
));

export default Input;
