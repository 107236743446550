import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import clsx from "clsx";

import * as styles from "./menu-item.module.scss";

const MenuItem = ({ children, className, ...props }) => (
  <Link
    className={clsx(styles.item, className)}
    activeClassName={styles.active}
    {...props}
  >
    {children}
  </Link>
);

export default MenuItem;
