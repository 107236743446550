import React from "react";
import Headroom from "react-headroom";

import Menu from "../Menu/Menu";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import Burger from "../../ui/Burger/Burger";

import Logo from "../../../images/logo.inline.svg";

import * as styles from "./header.module.scss";

const Header = () => (
  <Headroom className={styles.wrapper} upTolerance={15}>
    <div className={styles.header}>
      <Logo className={styles.logo} />
      <Menu />
      <div className={styles.burgerWrapper}>
        <LanguageSwitcher />
        {/* <Burger className={styles.burger} /> */}
      </div>
    </div>
  </Headroom>
);

export default Header;
