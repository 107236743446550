import React from "react";
import clsx from "clsx";

import * as styles from "./footer-link.module.scss";

const FooterLink = ({ className, children, ...props }) => (
  <a className={clsx(styles.link, className)} {...props}>
    {children}
  </a>
);

export default FooterLink;
