import { atom, selector } from "recoil";

const dataState = atom({
  key: "dataState",
  default: null,
});

export const menuState = selector({
  key: "menuState",
  get: ({ get }) => get(dataState)?.datoCmsMenu || {},
});

export const settingsState = selector({
  key: "settingsState",
  get: ({ get }) => get(dataState)?.datoCmsSetting || {},
});

export const casesSectionState = selector({
  key: "casesSectionState",
  get: ({ get }) => get(dataState)?.datoCmsCasesSection || {},
});

export const teamSectionState = selector({
  key: "teamSectionState",
  get: ({ get }) => get(dataState)?.datoCmsTeamSection || {},
});

export const servicesSectionState = selector({
  key: "servicesSectionState",
  get: ({ get }) => get(dataState)?.datoCmsServicesSection || {},
});

export const footerState = selector({
  key: "footerState",
  get: ({ get }) => get(dataState)?.datoCmsFooter || {},
});

export const contactFormState = selector({
  key: "contactFormState",
  get: ({ get }) => get(dataState)?.datoCmsContactForm || {},
});

export default dataState;
