import React from "react";
import clsx from "clsx";

import Section from "../Section/Section";
import ArrowDown from "../ArrowDown/ArrowDown";

import Bubble from "../../../images/bubble.inline.svg";

import * as styles from "./hero-section.module.scss";
import Div100vh from "react-div-100vh";
import ReactMarkdown from "react-markdown";

const HeroSection = ({ children, className, ...props }) => (
  <Div100vh className={clsx(styles.section, className)} {...props}>
    {children}
    <Bubble className={styles.bubble} />
    <ArrowDown className={styles.arrow} animated />
  </Div100vh>
);

export default HeroSection;
