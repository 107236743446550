import React from "react";
import { useRecoilValue } from "recoil";

import MenuItem from "../../ui/MenuItem/MenuItem";

import { menuState } from "../../../data/state";
import * as styles from "./menu.module.scss";

const Menu = () => {
  const { items } = useRecoilValue(menuState);
  if (!items) return null;

  return (
    <nav className={styles.menu}>
      {items.map(({ text, link }, i) => (
        <MenuItem to={link} key={i} className={styles.item}>
          {text}
        </MenuItem>
      ))}
    </nav>
  );
};

export default Menu;
