import React from "react";
import clsx from "clsx";

import GlossyCard from "../GlossyCard/GlossyCard";

import * as styles from "./service-category.module.scss";

const ServiceCategory = ({ title, children, className }) => (
  <GlossyCard className={clsx(styles.card, className)}>
    <div className={styles.content}>
      <h4 className={styles.title}>{title.toLowerCase()}</h4>
      <div className={styles.services}>{children}</div>
    </div>
  </GlossyCard>
);

export default ServiceCategory;
