import React, { forwardRef } from "react";
import clsx from "clsx";

import * as styles from "./textarea.module.scss";

const Textarea = forwardRef(({ className, ...props }, ref) => (
  <textarea className={clsx(styles.textarea, className)} {...props} ref={ref} />
));

export default Textarea;
