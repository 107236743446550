import React from "react";
import clsx from "clsx";
import useMedia from "use-media";
import { useRecoilValue } from "recoil";
import { GatsbyImage } from "gatsby-plugin-image";

import ArrowLink from "../ArrowLink/ArrowLink";

import * as styles from "./case.module.scss";
import { casesSectionState } from "../../../data/state";

const Case = ({ title, category, photo, description, reversed }) => {
  const wide = useMedia({ minWidth: "800px" });
  const { linkText } = useRecoilValue(casesSectionState);

  return (
    <article
      className={clsx(styles.article, reversed && wide && styles.reversed)}
    >
      <div className={styles.photoWrapper}>
        <GatsbyImage
          image={photo.gatsbyImageData}
          className={styles.photo}
          alt={title}
        />
      </div>

      <div className={styles.info}>
        <div className={styles.header}>
          <h3>{title}</h3>
          <span className={styles.category}>{category}</span>
          <div className={styles.description}>{description}</div>
        </div>

        {/* <ArrowLink to="#" reversed={wide && reversed}>
          {linkText}
        </ArrowLink> */}
      </div>
    </article>
  );
};

export default Case;
